import React, { useEffect, useState } from 'react';
import { bulkorder } from './utils/fetch';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import './UploadExcel.css'; 
import CustomLoader from './Loader';
import SuccessModal from './SuccessModal';
import sample_bulk_order from './assets/sample_bulk_order.csv'
import CSVFileValidator from 'csv-file-validator';

const UploadExcel = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [prompts, setPrompts] = useState('');
  const [password, setPassword] = useState('');
  const [csvData, setCsvData] = useState([]);
  const [message, setMessage] = useState('');
  const [btnState, setBtnState] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [orderloading, setOrderLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [is_prompt, setIs_prompt] = useState(false)
  const [autogenerateTaskId, setAutogenerateTaskId] = useState(false)
  const [autogenerateProgress, setAutogenerateProgress] = useState(0)

  const isEmailValid = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    const config = {
      headers: [
        { name: 'ID', inputName: 'ID', required: true },
        { name: 'last_name', inputName: 'last_name', required: true },
        { name: 'first_name', inputName: 'first_name', required: true },
        { name: 'email', inputName: 'email', required: true, unique: true, validate: isEmailValid },
        { name: 'shirt-size', inputName: 'shirt-size', required: true },
        { name: 'apparel', inputName: 'apparel', required: true },
        { name: 'price', inputName: 'price', required: true },
        { name: 'toggled', inputName: 'toggled', required: true },
        { name: 'streetAddress', inputName: 'streetAddress', required: true },
        { name: 'streetAddress2', inputName: 'streetAddress2', required: false },
        { name: 'city', inputName: 'city', required: true },
        { name: 'state', inputName: 'state', required: true },
        { name: 'postalZipcode', inputName: 'postalZipcode', required: true },
        { name: 'phone', inputName: 'phone', required: true },
        { name: 'color', inputName: 'color', required: true },
        { name: 'org_id', inputName: 'org_id', required: true },
        { name: 'org_name', inputName: 'org_name', required: true }
      ],
      isColumnIndexAlphabetic: true
    };
    CSVFileValidator(selectedFile, config)
      .then(csvData => {
        if (csvData.inValidData.length > 0) {
          console.log("csv file failed validation details: ", csvData.inValidData);
          setMessage("CSV file validation failed, check console for more detail");
          setBtnState(true);
        } else {
          setMessage("CSV file validation done, all data is correct!");
          Papa.parse(selectedFile, {
            header: true,
            complete: (results) => {
              const filteredData = results.data.filter(row =>  Object.values(row).some(value => value.trim() !== ""));
              setCsvData(filteredData);
            },
            error: (error) => {
              console.error('Error reading CSV file:', error);
              setMessage('Error reading CSV file');
            }
          });
        }
      });
  };

  const handlePromptsChange = (e) => {
    setPrompts(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleConfirm = async (e) => {
    setShowModal(false)
    e.preventDefault();
    if (!file || !prompts || !password) {
      setMessage('Please fill out all fields');
      return;
    }

    var task_id = (Date.now()).toString()
    const promptsArray = prompts.split(',').map(prompt => prompt.trim()).filter(prompt => prompt !== '');
    const formData = {
      file: csvData,
      prompts: promptsArray,
      numImages: csvData.length,
      password: password,
      is_prompt: is_prompt,
      is_toggled: false,
      task_id: task_id
    };
    setOrderLoading(true)
    try {
      setTimeout(()=>{setAutogenerateTaskId(task_id)}, 2000)
      const succeeded = await bulkorder(formData, navigate);
      setAutogenerateTaskId(false)
      if (Object.values(succeeded).every(item => item.order_id)) {
        console.log('Bulk Order Placed successfully:', formData);
        if (succeeded) {
          const csvData = jsonToCsv(succeeded);
          downloadCsv(csvData, 'bulk_order.csv');
          setShowSuccessModal(true);
          setTimeout(() => {
            navigate('/orders')
          }, 2000);
        } else {
          console.error('No data received to convert to CSV');
          setMessage('No data received to convert to CSV');
        }
        setMessage('Bulk Order Placed Successfully');
      } else if (succeeded === '403'|| parseInt(succeeded, 10) === 403) {
        setErrorMessage('Incorrect Password...');
      } else {
        console.error('Failed to Order:', succeeded.error);
        setMessage('Failed to Create Bulk Order');
      }
    } catch (error) {
      console.error('Error placing bulk order:', error);
      setMessage('Failed to upload file');
    } finally {
      setOrderLoading(false);
    }
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  function jsonToCsv(jsonArray) {
    if (!jsonArray || jsonArray.length === 0) {
      throw new Error('Empty or invalid JSON array');
    }

    const headers = Object.keys(jsonArray[0]);
    const csvRows = [headers.join(',')];

    for (const row of jsonArray) {
      const values = headers.map(header => {
        const value = row[header] !== undefined ? row[header] : '';
        const escaped = ('' + value).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }
  const handleSampleDownload = () => {
    const url = sample_bulk_order;
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sample_bulk_order.csv';
    a.click();
  };

  function downloadCsv(csvData, filename) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.click();
  }

  useEffect(() => {
    if(!autogenerateTaskId)
      return

    let server = process.env.REACT_APP_SERVER_NEW
    let con_type = server.indexOf('https') >= 0 ? 'wss' : 'ws'
    server = server.split('//')[1]
    const ws = new WebSocket(`${con_type}://${server}/ws/progress/autogenerate/${autogenerateTaskId}`);
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(data, (((data.success || 0) + (data.failed || 0)) / (data.total || 1)*100))
      setAutogenerateProgress((((data.success || 0) + (data.failed || 0)) / (data.total || 1))*100);
    };

    ws.onclose = () => {
      console.log("WebSocket closed");
      setAutogenerateProgress(0);
    };

    return () => {
      ws.close();
    };
  }, [autogenerateTaskId]);

  useEffect(() => {
    if (!file || !prompts || !password) {
      setBtnState(true);
      return;
    }
    setBtnState(false);
  }, [file, prompts, password]);
  const handleisprompt = (isChecked) => {
    setIs_prompt(isChecked);
  };

  return (
    <div className="upload-excel-container">
      {orderloading ? <>
      <div className="w-[100%] flex mt-3 ml-4">
        <progress value={autogenerateProgress} max="100" style={{width:"90%"}}></progress>
        <p className="ml-1">{Math.round(autogenerateProgress)}%</p>
      </div>
      <CustomLoader order_id={csvData.length}/> 
      </>
      :<>
                    <SuccessModal 
                    isOpen={showSuccessModal}
                    onClose={() => setShowSuccessModal(false)}
                    />
      <h2 className="upload-excel-header">Upload Bulk Order CSV File</h2>
      <form className="upload-excel-form" onSubmit={handleSubmit}>
        <div className="form-group">
        <label className="custom-checkbox-prompt">
            <input
              type="checkbox"
              checked={is_prompt}
              onChange={(e) => handleisprompt(e.target.checked)}
            />
            <span className="select-order-prompt">Prompt Mode</span>
          </label>
          <label htmlFor="prompts" className="form-label">Array of Themes/Prompts (use , to separate each line):</label>
          <textarea id="prompts" className="form-input" value={prompts} onChange={handlePromptsChange} />
          
          {is_prompt && <span className='Attendation-txt'>Ensure that the number of prompts matches the number of orders in the CSV file.!</span>}
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">Password:</label>
          <input type="password" id="password" className="form-input" value={password} onChange={handlePasswordChange} />
        </div>
        {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
        <div className="form-group">
          <label htmlFor="file" className="form-label">CSV File:</label>
          <input type="file" id="file" className="form-input" accept=".csv" onChange={handleFileChange} />
          <span type="button" className="form-button-sample" onClick={handleSampleDownload}>Download Sample CSV</span>
        </div>
        <button disabled={btnState} type="submit" className="form-button">Order Bulk</button>
      </form>
      {message && <p className="upload-excel-message">{message}</p>}
    </>}
    {showModal && (
        <div className="warning-modal">
          <div className="warning-modal-content">
            <h2 className="warning-modal-title">Attention!!!</h2>
            <p className="warning-modal-message">Are you sure you want to generate the images?</p>
            <p className="warning-modal-cost">Approximate cost: ${0.2 * csvData.length} for this image generation!!</p>
            <div className="warning-modal-actions">
              <button className="warning-modal-confirm-button" onClick={handleConfirm}>Confirm</button>
              <button className="warning-modal-cancel-button" onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadExcel;
