import axios from 'axios';
import app from './firebase-config';
import { 
    getAuth, 
  } from 'firebase/auth';
const auth = getAuth(app);
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_NEW, 
});

export default axiosInstance;
