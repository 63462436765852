import { useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export function useAutoLogout(user, setUser) {
    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        let timeout;

        const resetTimer = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (user) {
                    signOut(auth).then(() => {
                        setUser(null);
                        navigate('/login', { replace: true });
                        console.log('User has been logged out due to inactivity.');
                    }).catch((error) => {
                        console.error('Failed to sign out user:', error);
                    });
                }
            }, 10000); 
        };

        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);
        resetTimer(); 
        return () => {
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
            clearTimeout(timeout);
        };
    }, [user, setUser, auth, navigate]);
    return {};
}
