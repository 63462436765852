import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Logo from './images/logo_footer_t.png';
import { handleSignOut } from './signout';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <div className='flex top-bar w-full py-2 justify-between items-center' style={{ zIndex: '9', width: '100vw' }}>
        <Link to="/home">
          <img src={Logo} className='drophouse-logo' alt='drophouse logo' />
        </Link>
        <button onClick={handleSignOut} className='sign-out-button'>
          Sign Out
        </button>
      </div>
      <App />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
