// Loader.jsx
import React from 'react';
import './ZoomLoader.css'; // Import the CSS for the loader

const ZoomLoader = () => {
  return (
    <div className="loader-wrapper">
      <div className="loader"></div>
    </div>
  );
};

export default ZoomLoader;
