import React from 'react';
import HomePage from './HomePage';
import { Link } from 'react-router-dom';
import './Dashboard.css'
import Logo from './images/logo_footer_t.png'

const Dashboard = () => {

    const OrdersIcon = ({ size = 50 }) => {
        return(
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 3H21V21H3V3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M3 9H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M9 21V9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>)
    };
    const OrganisationsIcon = ({ size = 50 }) => {
        return(
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2L2 7H22L12 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M2 22H22V10H2V22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M6 18H18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>)
}

return(
    <section className='body w-full h-full' style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div className='main-menu flex' style={{ flex: 1, justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <div className='options flex' style={{ width: '50%', justifyContent: 'space-between' }}>
          <Link to="/orders" className='option'>
            <div className='icon-container'>
              <OrdersIcon size={70} />
              <p>Orders</p>
            </div>
          </Link>
          <Link to="/organisations" className='option'>
            <div className='icon-container'>
              <OrganisationsIcon size={70} />
              <p>Organizations</p>
            </div>
          </Link>
        </div>
      </div>
    </section>

)
};
export default Dashboard;