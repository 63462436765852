import React from 'react';
import './CustomLoader.css';

const CustomLoader = ({ order_id }) => (
  <div className="custom-loader-container">
    <div className="custom-loader-spinner"></div>
    <p className="custom-loader-text">Generating Image for {order_id} order{order_id > 1 ? `'s`:``} is Processing...</p>
    <p> It may take sometime, do not refresh or close the page.!!!</p>
  </div>
);

export default CustomLoader;
