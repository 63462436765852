import React, { useState, useEffect } from 'react';
import { updateOrg, fetchPrices, delete_org } from './utils/fetch';
import { useNavigate } from 'react-router-dom';
import './Organisation.css'; // Import CSS file for component styling
import ApparelPreview from './setImage';
import ConfirmationModal from './ConfirmationModal';
import { generate_presigned_url } from './utils/aws_utils'

const OrganisationDetailsModal = ({ organisation }) => {
    const Spinner = () => (
        <div className="spinner-overlay">
            <div className="spinner"></div>
        </div>
    );
    const navigate = useNavigate();
    const [editMode, setEditMode] = useState(false);
    const [editedOrganisation, setEditedOrganisation] = useState({ ...organisation });
    const [selectedImage, setSelectedImage] = useState(null);
    const [isSaving, setIsSaving] = useState(false); // State to manage saving status

    useEffect(() => {
        setEditedOrganisation({ ...organisation });
    }, [organisation]);

    const viewImage = async (base64Data) => {
        if(!base64Data)
            return;
        
        if(base64Data.includes('data:image')){
            setSelectedImage(base64Data);
        }
        else{
            var image_url = await generate_presigned_url(base64Data, 'drophouse-skeleton')
            console.log('generated image', base64Data, image_url)
            setSelectedImage(image_url)
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedOrganisation(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileInputChange = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (upload) => {
                setEditedOrganisation(prevState => ({
                    ...prevState,
                    [name]: upload.target.result
                }));
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const handleMaskUpload = (index, e) => {
        const { files } = e.target;
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (upload) => {
                const updatedProducts = [...editedOrganisation.Products];
                updatedProducts[index].Product_Mask = upload.target.result;
                setEditedOrganisation(prevState => ({
                    ...prevState,
                    Products: updatedProducts
                }));
            };
            reader.readAsDataURL(files[0]);
        }
    };
    const [defaultProd, setDefaultProd] = useState({
        index: '',
        is_prod_added: false
    })
    const handleDefaultProductUpload = (index, e) => {
        setDefaultProd({
            index: index,
            is_prod_added: true
        })
        const { files } = e.target;
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (upload) => {
                const updatedProducts = [...editedOrganisation.Products];
                updatedProducts[index].Product_DefaultProduct = upload.target.result;
                setEditedOrganisation(prevState => ({
                    ...prevState,
                    Products: updatedProducts
                }));
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const handleProductInputChange = (index, e) => {
        const { name, value } = e.target;
        const updatedProducts = [...editedOrganisation.Products];
        updatedProducts[index][name] = value;
        setEditedOrganisation(prevState => ({
            ...prevState,
            Products: updatedProducts
        }));
    };
    const handleProductColorChange = (productIndex, colorKey, field, event) => {
        const { value } = event.target;
        setEditedOrganisation((prevState) => {
            const updatedProducts = [...prevState.Products];
            const product = updatedProducts[productIndex];
            const updatedColors = { ...product.Product_Colors };
    
            // Update the specific color field
            updatedColors[colorKey] = {
                ...updatedColors[colorKey],
                [field]: value,
            };
    
            return {
                ...prevState,
                Products: updatedProducts.map((p, index) =>
                    index === productIndex ? { ...p, Product_Colors: updatedColors } : p
                ),
            };
        });
    };

    const handleProductFileInputChange = (index, color, assetType, e) => {
        const { files } = e.target;
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (upload) => {
                const updatedProducts = [...editedOrganisation.Products];
                if (!updatedProducts[index].Product_Colors[color].asset) {
                    updatedProducts[index].Product_Colors[color].asset = {};
                }
                updatedProducts[index].Product_Colors[color].asset[assetType] = upload.target.result;
                setEditedOrganisation(prevState => ({
                    ...prevState,
                    Products: updatedProducts
                }));
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const handleLandingPageInputChange = (index, e) => {
        const { name, value } = e.target;
        const updatedLandingPage = [...editedOrganisation.LandingPage];
        updatedLandingPage[index][name] = value;
        setEditedOrganisation(prevState => ({
            ...prevState,
            LandingPage: updatedLandingPage
        }));
    };

    const handleLandingPageFileInputChange = (index, e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (upload) => {
                const updatedLandingPage = [...editedOrganisation.LandingPage];
                updatedLandingPage[index][name] = upload.target.result;
                setEditedOrganisation(prevState => ({
                    ...prevState,
                    LandingPage: updatedLandingPage
                }));
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleDeleteOrg = (orgId) => {
        // Show the confirmation modal
        setIsModalVisible(true);
    };
    const handleConfirmDelete = async (password) => {        
        try {
            const payload ={
                org_id: editedOrganisation.Org_ID,
                password: password
            }
            const response = await delete_org(payload);
            if (!response.success) {
                throw new Error('Failed to delete organization');
            }
            else{
                console.log("organisation deleted successfully!!!")
            }
            alert('Organization deleted successfully');
            window.location.reload()
        } catch (error) {
            console.error(error);
            alert('Error deleting organization');
        } finally {
            setIsModalVisible(false);
        }
    };

    const handleCancelDelete = () => {
        // Hide the confirmation modal
        setIsModalVisible(false);
    };
    const handleSubmit = async () => {
        setIsSaving(true); // Show spinner
        try {
            const succeeded = await updateOrg(editedOrganisation.Org_ID, editedOrganisation, navigate);
            if (succeeded.success) {
                console.log('Organisation Updated successfully:', editedOrganisation);
            } else {
                console.error('Failed to update organisation:', succeeded.error);
            }
            setEditMode(false);
        } catch (error) {
            console.error('Error updating organisation:', error);
            // Handle error state or show user a notification
        } finally {
            setIsSaving(false); // Hide spinner
        }
    };
    const [editDimensionsProductIndex, setEditDimensionsProductIndex] = useState(null); // Track which product is being edited
    const handleAddProduct = () => {
        const newProduct = {
            Product_Name: '',
            Product_Description: '',
            Product_Default_Color: '',
            Product_Sizes: [],
            Product_Mask: '',
            Product_DefaultProduct: '',
            Product_Dimensions_Top: '',
            Product_Dimensions_Left: '',
            Product_Dimensions_Height: '',
            Product_Dimensions_Width: '',
            Product_Colors: {}
        };
        setEditedOrganisation((prevState) => ({
            ...prevState,
            Products: [newProduct, ...prevState.Products]
        }));
    };
    const handleAddColor = (productIndex) => {
        const newColor = {
            name: '',
            asset: {
                front: '',
                back: ''
            },
            color_map: ''
        };
        const updatedProducts = [...editedOrganisation.Products];
            const product = updatedProducts[productIndex];
            product.Product_Colors = {
                ...product.Product_Colors,
                [`${Date.now()}`]: newColor
            };
        setEditedOrganisation(prevState => ({
                ...prevState,
                Products: updatedProducts
        }));
    };
    const handleDeleteColor = (productIndex, colorKey) => {
        setEditedOrganisation((prevState) => {
            const updatedProducts = [...prevState.Products];
            const product = updatedProducts[productIndex];
            delete product.Product_Colors[colorKey];

            return {
                ...prevState,
                Products: updatedProducts
            };
        });
    };
    const handleDeleteProduct = (productIndex) => {
        setEditedOrganisation((prevState) => {
            const updatedProducts = [...prevState.Products];
            updatedProducts.splice(productIndex, 1); // Remove the product at the specified index
    
            return {
                ...prevState,
                Products: updatedProducts
            };
        });
    };
    
    const handleUpdateDimensions = (dimensions) => {
        const updatedProducts = [...editedOrganisation.Products];
            const product = updatedProducts[editDimensionsProductIndex];

            // Update the dimensions in the product
            product.Product_Dimensions_Top = dimensions.top;
            product.Product_Dimensions_Left = dimensions.left;
            product.Product_Dimensions_Height = dimensions.height;
            product.Product_Dimensions_Width = dimensions.width;
        setEditedOrganisation(prevState => ({
                ...prevState,
                Products: updatedProducts
        }));
        // Close the dimension editor after updating
        setEditDimensionsProductIndex(null);
        setDefaultProd({
            index: '',
            is_prod_added: false
        })
    };
    const [productsList, setProductsList] = useState([]);
    const handleSizeChange = (index, size) => {
        console.log("Size change occurs for size:", size);
        const updatedProducts = [...editedOrganisation.Products];
            const product = updatedProducts[index];
    
            console.log("Before update, Product_Sizes:", product.Product_Sizes);
    
            if (product.Product_Sizes.includes(size)) {
                updatedProducts[index].Product_Sizes = product.Product_Sizes.filter(s => s !== size);
            } else {
                updatedProducts[index].Product_Sizes = [...product.Product_Sizes, size];
            }
        setEditedOrganisation(prevState => ({
                ...prevState,
                Products: updatedProducts,
        }));
    };
    
    
    

    
    useEffect(() => {
        fetchPrices(navigate)
            .then(items => {
                const name = Object.keys(items);
                setProductsList(name);
            });
    }, []);

    return (
        <div className="organisation-details-modal">
            {isSaving && <Spinner />} {/* Show spinner when saving */}
            <div className='modal-heading'>
                <h2>Organisation Details</h2>
            </div>
            <div className='modal-body'>
            <p><strong>ID:</strong> {editedOrganisation.Org_ID}</p>
            {editMode ? (
                <div className="form-group">
                    <label>Name:</label>
                    <input type="text" name="Org_Name" value={editedOrganisation.Org_Name} onChange={handleInputChange} />
                </div>
            ) : (
                <p><strong>Name:</strong> {editedOrganisation.Org_Name}</p>
            )}
            {editMode ? (
                <div className="form-group">
                    <label>Mask:</label>
                    <input type="file" name="Mask" accept="image/png, image/jpeg" onChange={handleFileInputChange} />
                </div>
            ) : (
                <p><strong>Mask:</strong> {editedOrganisation.Mask}</p>
            )}
            <button onClick={() => viewImage(editedOrganisation.Mask)}>View Image</button>
            {editMode ? (
                <div className="form-group">
                    <label>Logo:</label>
                    <input type="file" name="Logo" accept="image/png, image/jpeg" onChange={handleFileInputChange} />
                </div>
            ) : (
                <p><strong>Logo:</strong> {editedOrganisation.Logo}</p>
            )}
            <button onClick={() => viewImage(editedOrganisation.Logo)}>View Image</button>
            {editMode ? (
                <div className="form-group">
                    <label>Green Mask:</label>
                    <input type="file" name="GreenMask" accept="image/png, image/jpeg" onChange={handleFileInputChange} />
                </div>
            ) : (
                <p><strong>Green Mask:</strong> {editedOrganisation.GreenMask}</p>
            )}
            <button onClick={() => viewImage(editedOrganisation.GreenMask)}>View Image</button>
            {editMode ? (
                <div className="form-group">
                    <label>Theme Color:</label>
                    <input type="text" name="Theme_Color" value={editedOrganisation.Theme_Color} onChange={handleInputChange} />
                </div>
            ) : (
                <p><strong>Theme Color:</strong> {editedOrganisation.Theme_Color}</p>
            )}
            {editMode ? (
                <div className="form-group">
                    <label>Font:</label>
                    <input type="text" name="Font" value={editedOrganisation.Font} onChange={handleInputChange} />
                </div>
            ) : (
                <p><strong>Font:</strong> {editedOrganisation.Font}</p>
            )}
            {editMode ? (
                <div className="form-group">
                    <label>Favicon:</label>
                    <input type="file" name="Favicon" accept="image/png, image/jpeg" onChange={handleFileInputChange} />
                </div>
            ) : (
                <p><strong>Favicon:</strong> {editedOrganisation.Favicon}</p>
            )}
            {editMode &&  <div className='add-product-section'>
                <button className="add-product-btn" onClick={handleAddProduct}>
                    Add Product
                </button>
            </div>}
           
            <div className='in-section'>
            <h3>Products:</h3>
            {editedOrganisation.Products.map((product, index) => (
                <div key={index} className="product-details grid grid-cols-3 gap-4">
                    <div className='col'>
                    {editMode ? (
                        <div className="form-group">
                            <label>Name:</label>
                            {/* <input type="text" name="Product_Name" value={product.Product_Name} onChange={(e) => handleProductInputChange(index, e)} /> */}
                            <select value={product.Product_Name} name="Product_Name" onChange={(e) => handleProductInputChange(index, e)} required>
                                        <option value="">Select a product</option>
                                        {productsList.map((option, idx) => (
                                            <option key={idx} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                        </div>
                    ) : (
                        <p><strong>Name:</strong> {product.Product_Name}</p>
                    )}
                    {editMode ? (
                        <div className="form-group">
                            <label>Description:</label>
                            <input type="text" name="Product_Description" value={product.Product_Description} onChange={(e) => handleProductInputChange(index, e)} />
                        </div>
                    ) : (
                        <p><strong>Description:</strong> {product.Product_Description}</p>
                    )}
                    {editMode ? (
                        <div className="form-group">
                            <label>Default Color:</label>
                            <input type="color" name="Product_Default_Color" value={product.Product_Default_Color} onChange={(e) => handleProductInputChange(index, e)} />
                        </div>
                    ) : (
                        <p><strong>Default Color:</strong> {product.Product_Default_Color}</p>
                    )}
                    {editMode ? (
                    <>
                     <label>Sizes:</label>
                                    <div className="checkbox-group">
                                        {['XS', 'S', 'M', 'L', 'XL', '2XL'].map(size => (
                                            <label key={size}>
                                                <input
                                                    type="checkbox"
                                                    checked={product.Product_Sizes.includes(size)}
                                                    onChange={() => handleSizeChange(index, size)}
                                                    required
                                                />
                                                {size}
                                            </label>
                                        ))}
                                    </div>
                    </>
                    ):
                    (<>
                    {product.Product_Sizes && product.Product_Sizes.length > 0 && (
                        <p><strong>Sizes:</strong> {product.Product_Sizes.join(', ')}</p>
                    )}
                    </>)}
                    </div>
                    <div>
                        <h4>Dimensions:</h4>
                        <p><strong>Top:</strong> {product.Product_Dimensions_Top}</p>
                        <p><strong>Left:</strong> {product.Product_Dimensions_Left}</p>
                        <p><strong>Height:</strong> {product.Product_Dimensions_Height}</p>
                        <p><strong>Width:</strong> {product.Product_Dimensions_Width}</p>
                        {editMode && defaultProd.index == index && defaultProd.is_prod_added && (
                            <>
                                        <button className="edit-dimensions-btn" onClick={() => setEditDimensionsProductIndex(index)}>
                                            Edit Dimensions
                                        </button>
                    {editDimensionsProductIndex !== null && editDimensionsProductIndex == index && (
                    <div>
                        <h2>Adjust Zoomer</h2>
                        <ApparelPreview
                            uploadedImage={editedOrganisation.Products[editDimensionsProductIndex].Product_DefaultProduct}
                            onUpdateDimensions={handleUpdateDimensions}
                        />
                    </div>
                    )}
                </>
                )}
                    </div>
                    <div className='col'>

                    {editMode ? (
                        <div className="form-group">
                            <label>Mask:</label>
                            <input type="file" name="Product_Mask" accept="image/png, image/jpeg" onChange={(e) => handleMaskUpload(index, e)} />
                        </div>
                    ) : (
                        <p><strong>Mask:</strong> {product.Product_Mask}</p>
                    )}
                    <button onClick={() => viewImage(product.Product_Mask)}>View Image</button>
                    {editMode ? (
                        <div className="form-group">
                            <label>Default Product:</label>
                            <input type="file" name="Product_DefaultProduct" accept="image/png, image/jpeg" onChange={(e) => handleDefaultProductUpload(index, e)} />
                        </div>
                    ) : (
                        <p><strong>Default Product:</strong> {product.Product_DefaultProduct}</p>
                    )}
                    <button onClick={() => viewImage(product.Product_DefaultProduct)}>View Image</button>
                    </div>
                    
                    
                    
                    <div className='col-span-3'>
                    {product.Product_Colors && Object.keys(product.Product_Colors).length > 0 && (
                        <div>
                            <h4>Colors:</h4>
                            <div className='grid grid-cols-4 gap-4'>
                            {Object.keys(product.Product_Colors).map((color, idx) => (
                                <div className='col'>
                                <div key={idx}>
                                    <p><strong>Color ID:</strong> {color}</p>
                                    {editMode ? (
                                        <div className="form-group">
                                            <label>Name:</label>
                                            <input type="text" name="name" value={product.Product_Colors[color].name} onChange={(e) => handleProductColorChange(index, color, 'name', e)} />
                                        </div>
                                    ) : (
                                        <p><strong>Name:</strong> {product.Product_Colors[color].name}</p>
                                    )}
                                    {editMode ? (
                                        <div className="form-group">
                                            <label>Front Asset:</label>
                                            <input type="file" name="assetFront" accept="image/png, image/jpeg" onChange={(e) => handleProductFileInputChange(index, color, 'front', e)} />
                                        </div>
                                    ) : (
                                        <p><strong>Front Asset:</strong> {product.Product_Colors[color].asset?.front}</p>
                                    )}
                                    <button onClick={() => viewImage(product.Product_Colors[color].asset?.front)}>View Front Image</button>
                                    {editMode ? (
                                        <div className="form-group">
                                            <label>Back Asset:</label>
                                            <input type="file" name="assetBack" accept="image/png, image/jpeg" onChange={(e) => handleProductFileInputChange(index, color, 'back', e)} />
                                        </div>
                                    ) : (
                                        <p><strong>Back Asset:</strong> {product.Product_Colors[color].asset?.back}</p>
                                    )}
                                    <button onClick={() => viewImage(product.Product_Colors[color].asset?.back)}>View Back Image</button>
                                    {editMode ? (
                                    <div className="form-group">
                                            <label>Color Map:</label>
                                            <input type="color" name="name" value={product.Product_Colors[color].color_map} onChange={(e) => handleProductColorChange(index, color, 'color_map', e)} />
                                        </div>
                                        ) : <p><strong>Color Map:</strong> {product.Product_Colors[color].color_map}</p>}
                                        {editMode && (
                                                        <button className="delete-color-btn" onClick={() => handleDeleteColor(index, color)}>
                                                            Delete Color
                                                        </button>
                                                    )}
                                    
                                </div>
                            </div>
                            ))}
                            </div>
                            </div>
                    )}
                    {/* Add Color Button */}
                    {editMode && (
                                    <button className="add-color-btn" onClick={() => handleAddColor(index)}>
                                        Add Color
                                    </button>
                                )}
                    </div>

            {editMode && <button className='delete-color-btn' onClick={() => {handleDeleteProduct(index)}}>
            Delete Product</button>}
                </div>
            ))}
            </div>
            <h3>Landing Page:</h3>
            <div className=' grid grid-cols-4 gab-4'>
            {editedOrganisation.LandingPage.map((landingPage, index) => (
                <div key={index} className="landing-page-details">
                    <label><b>Sample Product {index + 1}</b></label>
                    {editMode ? (
                        <div className="form-group">
                            <label> Name:</label>
                            <input type="text" name="SampleProduct_Name" value={landingPage.SampleProduct_Name} onChange={(e) => handleLandingPageInputChange(index, e)} />
                        </div>
                    ) : (
                        <p><strong> Name:</strong> {landingPage.SampleProduct_Name}</p>
                    )}
                    {editMode ? (
                        <div className="form-group">
                            <label>Front Image:</label>
                            <input type="file" name="SampleProduct_Asset_Front" accept="image/png, image/jpeg" onChange={(e) => handleLandingPageFileInputChange(index, e)} />
                        </div>
                    ) : (
                        <p><strong>Front Image:</strong> {landingPage.SampleProduct_Asset_Front}</p>
                    )}
                    <button onClick={() => viewImage(landingPage.SampleProduct_Asset_Front)}>View Image</button>
                    {/* Sample Back Asset */}
                    {editMode ? (
                        <div className="form-group">
                            <label>Back Image:</label>
                            <input type="file" name="SampleProduct_Asset_Back" accept="image/png, image/jpeg" onChange={(e) => handleLandingPageFileInputChange(index, e)} />
                        </div>
                    ) : (
                        <p><strong>Back Image:</strong> {landingPage.SampleProduct_Asset_Back}</p>
                    )}
                    <button onClick={() => viewImage(landingPage.SampleProduct_Asset_Back)}>View Image</button>
                </div>
            ))}
            </div>
            {/* <h2>Adjust Zoomer</h2>
                <div>
                    {editedOrganisation.Products.map((product, index) => (
                        <ApparelPreview key={index} uploadedImage={product.Product_DefaultProduct} 
                            onUpdateDimensions={(dimensions) => handleUpdateDimensions(index, dimensions)}/>
                    ))}
                    <button className="add-btn" type="submit" style={{marginTop:'10%'}}>Submit</button>
                </div> */}
            <div className="action-buttons" style={{ display: 'block' }}>
                {editMode ? (
                    <>
                        <button onClick={handleSubmit}>Save Changes</button>
                        <button onClick={toggleEditMode}>Cancel</button>
                        <button className='delete-color-btn' onClick={()=>handleDeleteOrg(editedOrganisation.Org_ID)}>Delete Organization</button>
                    </>
                ) : (
                    <button onClick={toggleEditMode}>Edit Details</button>
                )}
            </div>
            {selectedImage && (
                <>
                    <div className="backdrop" onClick={() => setSelectedImage(null)}></div>
                    <div className="image-modal">
                        <span className="close-button" onClick={() => setSelectedImage(null)}>&times;</span>
                        <img src={selectedImage} alt="Selected Product" />
                    </div>
                </>
            )}
            </div>
            <ConfirmationModal
                isVisible={isModalVisible}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        </div>
    );
};

export default OrganisationDetailsModal;


