import React, { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import HomePage from './HomePage';
import OrganisationList from './Organisation';
import Dashboard from './Dashboard';
import UploadExcel from './bulkorder';
 
import { MessageBannerProvider } from "./context/MessageBannerContext";

function App() {
  const [user, setUser] = useState(null);

  return (
    <MessageBannerProvider>
      <Routes>
        <Route path="/login" element={<Login setUser={setUser} />} />
        <Route path="/home" element={user ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/orders" element={user ? <HomePage /> : <Navigate to="/login" />} />
        <Route path="/organisations" element={user ? <OrganisationList /> : <Navigate to="/login" />} />
        <Route path="/bulkorder" element={user ? <UploadExcel /> : <Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </MessageBannerProvider>
  );
}

export default App;
