import React from 'react';
import './RadioButtonGroup.css'; // Import CSS for styling

const RadioButtonGroup = ({ name, value, onChange }) => {
    return (
        <div className="radio-button-group">
            <label>
                <input
                    type="radio"
                    name={name}
                    value="true"
                    checked={value === true}
                    onChange={onChange}
                />
                True
            </label>
            <label>
                <input
                    type="radio"
                    name={name}
                    value="false"
                    checked={value === false}
                    onChange={onChange}
                />
                False
            </label>
        </div>
    );
};

export default RadioButtonGroup;
