import React, { useState } from 'react';
import './ConfirmationModal.css'; // Import CSS for styling

const ConfirmationModal = ({ isVisible, onConfirm, onCancel }) => {
    const [password, setPassword] = useState('');
    const Cancelsvg = () => {
        return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 21h22L12 2 1 21zM12 16v-4h2v4h-2zm0 2v-2h2v2h-2z" fill="#FFC107"/>
      </svg>
      )
    }

    const handleConfirm = () => {
        onConfirm(password);
    };

    if (!isVisible) return null;

    return (
        <div className="modal-overlay-confirm">
            <div className="modal-content-confirm">
                <Cancelsvg />
                <h2>Warning!!!</h2>
                <p>This action can't be undone. Are you sure you want to delete the organization?</p>
                <p>Enter password to delete the organization</p>
                <input
                    className='form-input'
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className="modal-buttons-confirm">
                    <button className='confirm-button' onClick={handleConfirm}>Confirm</button>
                    <button className='cancel-button' onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
