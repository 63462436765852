import React from 'react';
import './SuccessModal.css';

const SuccessModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="success-modal-overlay">
      <div className="success-modal-content">
        <h2>Image Regeneration Completed Successfully!</h2>
        <h4>Page will get refresh in a second!!!!</h4>
        {/* <button className="success-modal-close-btn" onClick={onClose}>Close</button> */}
      </div>
    </div>
  );
};

export default SuccessModal;
