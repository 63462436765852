import React, { useState, useMemo, useRef, useEffect, useContext } from 'react';
import { Rnd } from 'react-rnd';
import { useNavigate } from 'react-router-dom';

const ApparelPreview = ({ uploadedImage, onUpdateDimensions }) => {

    const navigate = useNavigate();
    const [imgTop, SetImgTop] = useState();
    const [imgLeft, SetImgLeft] = useState();
    const [imgWidth, SetImgWidth] = useState();
    const [imgHeight, SetImgHeight] = useState();
    const [saved, setSave] = useState(false)
    
    const imgRef = useRef(null);
    const divRef = useRef(null);
    const [style, setStyle] = useState({});
    const [dimensions, setDimensions] = useState({
        width: 100,
        height: 100,
        x: 0,
        y: 0,
    });

    const getPreviewImgClass = (apparel) => {
        switch (apparel) {
            case 'mug':
                return 'mugPreviewImg';
            case 'cap':
                return 'capPreviewImg';
            default:
                return 'previewImg';
        }
    };

    useEffect(() => {
        const updateDimensions = () => {
            if (imgRef.current && divRef.current) {
                const imgWidth = imgRef.current.clientWidth;
                const imgHeight = imgRef.current.clientHeight;
                setStyle({
                    width: imgWidth,
                    height: imgHeight
                });
            }
        };

        const imgElement = imgRef.current;
        if (imgElement.complete) {
            updateDimensions();
        } else {
            imgElement.onload = updateDimensions;
        }

        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [uploadedImage]);

    const handleDrag = (e, d) => {
        if (divRef.current) {
            const parentWidth = divRef.current.clientWidth;
            const parentHeight = divRef.current.clientHeight;
            const newX = dimensions.x + (d.deltaX / parentWidth) * 100;
            const newY = dimensions.y + (d.deltaY / parentHeight) * 100;

            setDimensions((prev) => ({
                ...prev,
                x: newX,
                y: newY
            }));
            SetImgTop(newY);
            SetImgLeft(newX);
            console.log('Top: ', newY);
            console.log('Left: ', newX);
            console.log('Width: ', imgWidth);
            console.log('Height: ', imgHeight);
        }
    };

    const handleResizeStop = (e, direction, ref, delta, position) => {
        const newX = (position.x / ref.parentElement.clientWidth) * 100;
        const newY = (position.y / ref.parentElement.clientHeight) * 100;
        const widthPercentage = (ref.offsetWidth / ref.parentElement.clientWidth) * 100;
        const heightPercentage = (ref.offsetHeight / ref.parentElement.clientWidth) * 100;
        setDimensions({
            width: widthPercentage,
            height: heightPercentage,
            x: newX,
            y: newY
        });
        SetImgTop(newY);
        SetImgLeft(newX);
        SetImgHeight(heightPercentage);
        SetImgWidth(widthPercentage);
        console.log(' Top : ', imgTop);
        console.log(' Left : ', imgLeft);
        console.log(' Height : ', imgHeight);
        console.log(' Width : ', imgWidth);
    };

    function dashboardRedirect(event) {
        event.preventDefault();
        event.stopPropagation();
        if ((!isNaN(imgTop) && imgTop > 0) || (!isNaN(imgLeft) && imgLeft > 0) || (!isNaN(imgHeight) && imgHeight > 0) || (!isNaN(imgWidth) && imgWidth > 0)) {
            // Example: Navigating to another page with updated dimensions
            onUpdateDimensions({
                top: imgTop,
                left: imgLeft,
                height: imgHeight,
                width: imgWidth
            });
            setSave(true);
            // navigate('/product');
            console.log('success')
        } else {
            console.log('Error redirecting');
        }
    }

    return (
        <div className={`${getPreviewImgClass('tshirt')}`} style={{ marginTop: '1%'}}>
            <span>Please adjust the red colour box around your logo.</span>
            <div ref={divRef} style={{ ...style, position: 'relative' }}>
                <Rnd
                    size={{ width: `${dimensions.width}%`, height: `${dimensions.height}%` }}
                    onDrag={handleDrag}
                    onResizeStop={handleResizeStop}
                    bounds="parent"
                    lockAspectRatio={true}
                    style={{ border: '1px solid red' }}
                    enableResizing={{
                        topRight: true,
                        bottomRight: true,
                        bottomLeft: true,
                        topLeft: true,
                    }}
                >
                    <div className="draggable-div"></div>
                </Rnd>
                {uploadedImage && <img style={{maxWidth:'510px',height:'100%'}} src={uploadedImage} ref={imgRef} alt="Product" />}
            </div>
            <button className='btn-mbl' style={{ backgroundColor: 'rgb(128, 0, 0)', color: 'white', borderRadius: '5px', marginTop: '10px' }}
                onClick={(event) => {
                    dashboardRedirect(event);
                }}
            >Save</button>{saved && <span>Dimensions Saved</span>}
        </div>
    );
};

export default ApparelPreview;
