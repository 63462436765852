// signOut.js
import  app  from './firebase-config';
import { getAuth, signOut } from 'firebase/auth';

export const handleSignOut = async () => {
    const auth = getAuth(app);
  try {
    await signOut(auth);
    console.log('User signed out');
    window.location.reload();
    // Optionally, redirect to the login page or home page
  } catch (error) {
    console.error('Error signing out:', error);
  }
};
