import React, { useState, useEffect, useContext } from 'react';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useAutoLogout } from './Inactivity';
import { useNavigate } from 'react-router-dom';
import axiosInstance from "./axiosInstance";
import { MessageBannerContext } from "./context/MessageBannerContext";
import MessageBanner from "./components/MessageBanner";
import app from './firebase-config';

function handleErrorCodes(errorcode)
{
    const errorMessage = {
        "auth/invalid-credential": "Invalid email or password",
        "auth/too-many-requests": "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later"
    }
    return (errorcode && errorMessage[errorcode]) ? errorMessage[errorcode] : errorcode;
}
function Login({ setUser }) {
  const auth = getAuth(app);
  const [email, setEmail] = useState('');
  const user = auth.currentUser;
  console.log('User from login:', user)
  const { activity } = useAutoLogout(user, setUser);
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); 
  const {
    showMessageBanner,
    setShowMessageBanner,
    messageBannerText,
    setMessageBannerText,
    bannerKey,
    setBannerKey
  } = useContext(MessageBannerContext);
  
  useEffect(() => {
    setShowMessageBanner(false)
  }, []);

useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async(user) => {
        const res = await axiosInstance.get("/admin_users");
        const allowedUsers = res.data;
        if (user && allowedUsers.includes(user.email)) {
            setUser(user);
            navigate('/home'); 
        }
    });

    return () => unsubscribe();
    }, [setUser, auth, navigate]);
const handleLogin = async (event) => {
    event.preventDefault();
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
        setShowMessageBanner(true);
        setMessageBannerText(handleErrorCodes(error.code));
        setBannerKey(prevKey => prevKey + 1);
    }
};

return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
        {showMessageBanner && <MessageBanner message={messageBannerText} keyTrigger={bannerKey} />}
        <div className="w-full max-w-xs">
            <h1 className="mb-6 text-3xl text-center text-gray-900">Login</h1>
            <form onSubmit={handleLogin} className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="password">
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        className="w-full px-3 py-2 mb-3 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="flex items-center justify-between">
                    <button
                        type="submit"
                        className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                    >
                        Login
                    </button>
                </div>
            </form>
        </div>
    </div>
);
}
export default Login;